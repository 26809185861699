
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-select
          outlined
          dense
          v-model="subscriberType"
          :items="tipoCliente"
          item-text="tipo"
          item-value="id"
          label="Tipo de cliente"
          return-object
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-show="ShowcontactFiscalId">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="empresa"
          v-show="ShowcontactFiscalId"
          label="Empresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-show="ShowcontactFiscalId"
          v-model="contactFiscalId"
          label="CIF empresa"
          :rules="rulescif"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="nombre"
          :label="$t('orderForm.nombre')"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="apellidos"
          :label="$t('orderForm.apellidos')"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="email"
          :rules="rulesCorreo"
          label="Email"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="movil"
          :rules="rulesMovil"
          :label="$t('orderForm.movil')"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="fiscalId"
          label="DNI"
          :rules="rulesFiscalId"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu
          :close-on-content-click="false"
          ref="dialog"
          :return-value.sync="fecha_nacimiento"
          persistent
          width="290px"
          v-model="menu"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="fecha_nacimiento_formulario"
              :label="$t('orderForm.fechaNacimiento')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha_nacimiento"
            :max="
              new Date(Date.now() - 567648000000).toISOString().substr(0, 10)
            "
            min="1920-01-01"
            :active-picker.sync="activePicker"
            scrollable
            :first-day-of-week="1"
            locale="es-es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn
              text
              color="primary"
              @click="seleccionarFechaNacimiento()"
              :disabled="this.bloquearBotonGardarFechaNacemento"
            >
              {{ $t("orderForm.botonGuardar") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          type="number"
          v-model="cp"
          :counter="5"
          label="Código Postal"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <model-list-select
          outlined
          dense
          :list="calles"
          v-model="calleSeleccionada"
                     option-value="nombreConcatenado"
                option-text="nombreConcatenado"
          class="form-datos-cliente-border"
          :placeholder="$t('orderForm.calle')"
          @select="onSelectCalle"
          :isDisabled="this.isDisabledList"
        >
        </model-list-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          outlined
          dense
          v-model="selectNumeroCalle"
          :items="arrayNumerosCalles"
          item-text="valor"
          item-value="valor"
          label="Número"
          return-object
          @change="onSelectNumeroCalle"
          :disabled="this.bloquearSelectorNumero"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-show="this.barraLoading">
      <v-col>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario">
      <v-col cols="12" sm="4">
        <v-select
          outlined
          dense
          v-model="selectPlanta"
          :items="arrayDirecciones"
          item-text="piso"
          v-show="this.ocultarBotonCalleNumeroFormulario"
          item-value="piso"
          label="Planta"
          @change="onSelectNumeroPlanta()"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          outlined
          dense
          v-model="selectPuerta"
          :items="this.arrayPuertas"
          item-text="puerta"
          v-show="this.ocultarBotonCalleNumeroFormulario"
          item-value="puerta"
          @change="onSelectNumeroPuerta()"
          :label="$t('orderForm.puerta')"
          return-object
        ></v-select>
      </v-col>

      <v-col cols="12" sm="4">
        <v-select
          outlined
          dense
          v-model="selectEscalera"
          :items="arrayDirecciones"
          item-text="escalera"
          item-value="escalera"
          v-show="this.ocultarBotonCalleNumeroFormulario"
          :label="$t('orderForm.escalera')"
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-checkbox
          :label="$t('orderForm.direccionFacturacion')"
          color="primary"
          value
          input-value="true"
          @click="checkbox()"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row style="margin-bottom:25px" v-show="!checkboxValue">
      <v-col cols="12" sm="12">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionDireccion"
          label="Dirección"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionPoblacion"
          :label="$t('orderForm.poblacion')"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionProvincia"
          label="Provincia"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          type="number"
          v-model="direccionFacturacionCp"
          label="Código Postal"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row style="margin-top:-40px">
      <v-col cols="12" sm="12">
        <v-checkbox v-model="aceptarCondiciones" color="primary">
          <div slot="label">
            {{ $t("orderForm.textoAceptarCondiciones.texto") }}
            <a @click.stop href="/#/informacion-basica-datos" target="_blank">{{
                $t("orderForm.textoAceptarCondiciones.textoEnlace")
              }}</a>*
          </div>
        </v-checkbox>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          v-model="numeroCuenta"
          :label="$t('orderForm.numeroCuenta')"
          required
          :rules="rulesIBAN"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">

          <v-alert
      shaped
      outlined
      color="primary"
      type="success"
    >
    {{ $t('orderForm.numeroConta') }}
    </v-alert>
      </v-col>
    </v-row>
    -->
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="snack_time"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { uuid } from 'vue-uuid'
import { ModelListSelect } from 'vue-search-select'

export default {
    components: {
        ModelListSelect
    },
    data () {
        return {
            tipoCliente: [
                { tipo: 'Particular', id: 0 },
                { tipo: 'Empresa', id: 1 }
            ],
            espera: 6000,
            date: null,
            menu: false,
            tipoVial: '',
            isDisabledList: true,
            activePicker: null,
            checkboxValue: true,
            aceptarCondiciones: '',
            direccionFacturacionProvincia: '',
            direccionFacturacionPoblacion: '',
            direccionFacturacionCp: '',
            direccionFacturacionDireccion: '',
            modal: false,
            subscriberType: '',
            documentType: '',
            files: [],
            empresa: '',
            fiscalId: '',
            contactFiscalId: '',
            ShowcontactFiscalId: false,
            nombre: '',
            apellidos: '',
            cp: '',
            poblacion: '',
            provincia: 'Lugo',
            email: '',
            movil: '',
            fecha_nacimiento: '',
            fecha_nacimiento_formulario: '',
            nacionalidad: 'Española',
            // numeroCuenta: '',
            file: null,
            snackbar: false,
            snack_color: '',
            snack_text: '',
            snack_time: -1,
            url: process.env.VUE_APP_API_URL,
            selectPuerta: '',
            selectEscalera: '',
            selectPlanta: '',
            selectNumeroCalle: '',
            ocultarBotonCalleNumeroFormulario: false,
            bloquearSelectorNumero: true,
            bloquearBotonGardarFechaNacemento: false,
            calles: [],
            arrayNumerosCalles: [],
            arrayDirecciones: [],
            arrayPuertas: [],
            calleSeleccionada: {},
            apartment_id: '',
            barraLoading: false,
            rulesTextSimple: [
                (v) =>
                    /^([A-Za-z0-9-,.àèìòùÀÈÌÒÙáéíóúÁÉÍÓÚñÑëü\s])*$/.test(v) ||
          'Carácteres inválidos.',
                (v) => !!v || this.$t('orderForm.errores.campoObligatorio')
            ],
            rulesCorreo: [
                (v) =>
                    /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@"]{2,})$/.test(
                        v
                    ) || this.$t('orderForm.errores.email')
            ],
            rulesMovil: [
                (v) =>
                    /^(\+34|0034|34)?[ .-]*(6|7|8|9)[ .-]*(\d[ .-]*){8}$/.test(v) ||
          this.$t('orderForm.errores.movil')
            ],

            rulesFiscalId: [
                (v) => /^[0-9]{8}[a-zA-Z]$/.test(v) || this.$t('orderForm.errores.dni')
            ],
            rulescif: [
                (v) =>
                    /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(v) ||
          this.$t('orderForm.errores.cif')
            ],
            rulesIBAN: [
                (v) =>
                    /^([A-Z]{2}[ \]?[0-9]{2})(?=(?:[\]?[A-Z0-9]){20}$)((?:[\]?[A-Z0-9]{3,5}){2,7})([\]?[A-Z0-9]{1,3})?$/.test(
                        v
                    ) || 'IBAN incorrecto, ES004915000512345666xxxx'
            ],
            options1: [
                { code: '01', name: 'aa', desc: 'desc01' },
                { code: '02', name: 'ab', desc: 'desc02' },
                { code: '03', name: 'bc', desc: 'desc03' },
                { code: '04', name: 'cd', desc: 'desc04' },
                { code: '05', name: 'de', desc: 'desc05' },
                { code: '06', name: 'ef', desc: 'desc06' }
            ],
            objectItem: {},
            options2: [
                { code: '01', name: 'aa', desc: 'desc01' },
                { code: '02', name: 'ab', desc: 'desc02' },
                { code: '03', name: 'bc', desc: 'desc03' },
                { code: '04', name: 'cd', desc: 'desc04' },
                { code: '05', name: 'de', desc: 'desc05' },
                { code: '06', name: 'ef', desc: 'desc06' }
            ],
            stringItem: ''
        }
    },
    computed: {
        comprobarEdad () {
            return this.fecha_nacimiento
        },
        CambiarCp () {
            return this.cp
        },

        CambioCalle () {
            return this.calleSeleccionada
        },

        canGuardar () {
            const patterCorreo =
        /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@"]{2,})$/
            //   const patternMovil = /^\+?[6-7]{1}-?[0-9]{8}$/
            const patternMovil =
        /^(\+34|0034|34)?[ .-]*(6|7|8|9)[ .-]*(\d[ .-]*){8}$/

            const patterDNI = /^[0-9]{8}[a-zA-Z]$/
            const patternTextoSimple =
        /^([A-Za-z0-9-,.àèìòùÀÈÌÒÙáéíóúÁÉÍÓÚñÑëü\s])*$/
            const patternCIF = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/
            //   var IBAN = require('iban')
            if (
                this.subscriberType.tipo !== '' &&
        this.documentType !== '' &&
        this.fecha_nacimiento !== '' &&
        (this.documentType !== 4 ||
          (this.documentType === 4 &&
            this.empresa !== '' &&
            patternTextoSimple.test(this.empresa) &&
            this.contactFiscalId !== '' &&
            patternCIF.test(this.contactFiscalId))) &&
        this.aceptarCondiciones &&
        this.email !== '' &&
        this.nombre !== '' &&
        patternTextoSimple.test(this.nombre) &&
        this.apellidos !== '' &&
        patternTextoSimple.test(this.apellidos) &&
        this.movil !== '' &&
        this.calleSeleccionada !== '' &&
        this.arrayPuertas.length > 0 &&
        this.provincia !== '' &&
        patternTextoSimple.test(this.provincia) &&
        this.poblacion !== '' &&
        // this.numeroCuenta !== '' &&
        patterCorreo.test(this.email) &&
        this.apartment_id !== '' &&
        patternMovil.test(this.movil) &&
        // IBAN.isValid(this.numeroCuenta) &&
        (patterDNI.test(this.fiscalId) || patternCIF.test(this.fiscalId)) &&
        (this.checkboxValue ||
          (!this.checkboxValue &&
            this.direccionFacturacionProvincia !== '' &&
            this.direccionFacturacionPoblacion !== '' &&
            this.direccionFacturacionCp !== '' &&
            this.direccionFacturacionDireccion !== ''))
            ) {
                return true
            } else {
                return false
            }
        },
        VtipoCliente () {
            return this.subscriberType
        }
    },
    watch: {
        menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        CambiarCp (value) {
            this.comprobarCP()
        },
        CambioCalle (value) {
            this.onSelectCalle(value)
        },
        comprobarEdad (value) {
            this.calcularEdad(value)
        },
        VtipoCliente (value) {
            if (value.id === 1) {
                this.ShowcontactFiscalId = true
                this.documentType = 4
            }
            if (value.id === 0) {
                this.ShowcontactFiscalId = false
                this.empresa = ''
                this.documentType = 0
                this.contactFiscalId = ''
            }
        },
        canGuardar (value) {
            if (value) {
                this.enviarFormulario()
            } else {
                this.$store.dispatch('setdatosClienteValidados', false)
            }
        }
    },
    methods: {
        update () {
            this.$forceUpdate()
        },
        checkbox () {
            if (this.checkboxValue) {
                this.checkboxValue = false
            } else {
                this.checkboxValue = true
            }
        },
        seleccionarFechaNacimiento () {
            const fechaFormateada = new Date(this.fecha_nacimiento)
            this.fecha_nacimiento_formulario = fechaFormateada.toLocaleDateString()
            this.$refs.dialog.save(this.fecha_nacimiento)
        },
        go (msg, color, time) {
            this.snack_time = time
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        onSelectNumeroCalle () {
            this.arrayDirecciones = []
            this.selectPuerta = ''
            this.selectEscalera = ''
            this.selectPlanta = ''
            this.comprobarDireccion()
        },

        onSelectNumeroPlanta () {
            this.arrayPuertas = []
            this.arrayDirecciones.forEach((element) => {
                if (element.piso === this.selectPlanta.piso) {
                    this.arrayPuertas.push(element)
                }
            })
        },
        onSelectNumeroPuerta () {
            this.comprobarCobertura()
        },

        onSelectCalle (calleSeleccionada) {
            this.arrayDirecciones = []
            this.selectPuerta = ''
            this.selectEscalera = ''
            this.selectPlanta = ''
            this.selectNumeroCalle = ''
            this.ocultarBotonCalleNumeroFormulario = false
            this.arrayNumerosCalles = []
            if (calleSeleccionada.nombre) {
                this.calleSeleccionada = calleSeleccionada
                this.arrayNumerosCalles = []
                if (this.calleSeleccionada.nombre) {
                    this.listarNumerosCalle()
                }
            } else {
                this.bloquearSelectorNumero = true
                this.selectNumeroCalle = ''
                this.ocultarBotonCalleNumeroFormulario = false
            }
        },
        listarNumerosCalle () {
            const poblacion = this.calleSeleccionada.poblacion
            this.bloquearSelectorNumero = false
            this.poblacion = this.calleSeleccionada.poblacion
                .trim()
                .replace(/\s+/g, '%20')
            const calle = this.calleSeleccionada.nombre.trim().replace(/\s+/g, '%20')
            const golistarNumerosCalle = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + 'cobertura/poblacion/' + poblacion + '/calle/' + calle,
                        config
                    )
                } catch (error) {
                    this.go(this.$t('errorConexionApi'), 'error')
                }
            }

            const processListarNumerosCalle = async () => {
                const listarNumerosCalle_result = await golistarNumerosCalle()
                if (listarNumerosCalle_result.data.data.length > 0) {
                    listarNumerosCalle_result.data.data.forEach((element) => {
                        var numeroCalle = {
                            valor: element.numero
                        }

                        this.arrayNumerosCalles.push(numeroCalle)
                    })
                }
            }

            processListarNumerosCalle()
        },
        addZero (i) {
            if (i < 10) {
                i = '0' + i
            }
            return i
        },
        calcularEdad (fecha_nacimiento) {
            var hoy = new Date()
            var cumpleanos = new Date(fecha_nacimiento)
            var edad = hoy.getFullYear() - cumpleanos.getFullYear()
            var m = hoy.getMonth() - cumpleanos.getMonth()
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--
            }
            if (edad < 18) {
                this.go(this.$t('orderForm.errores.menorEdad'), 'error')
                this.bloquearBotonGardarFechaNacemento = true
            } else {
                this.bloquearBotonGardarFechaNacemento = false
            }
        },
        comprobarCobertura () {
            var direccionFibra = this.arrayDirecciones.filter(
                (direccion) =>
                    direccion.puerta === this.selectPuerta.puerta &&
          direccion.piso === this.selectPlanta.piso &&
          direccion.escalera === this.selectPlanta.escalera
            )

            if (direccionFibra[0]) {
                this.$store.commit(
                    'SET_CODIGOCOBERTURAFIBRA',
                    direccionFibra[0].apartment_id
                )

                this.apartment_id = direccionFibra[0].apartment_id
                var order_infoJ = this.$store.getters.getjsonPedido

                if (!order_infoJ) {
                    var lineaFibra = this.$store.getters.getFibra
                    var date_format = new Date()

                    const mes = date_format.getMonth() + 1

                    const h = this.addZero(date_format.getUTCHours())
                    const m = this.addZero(date_format.getMinutes())
                    const s = this.addZero(date_format.getSeconds())
                    const time = h + ':' + m + ':' + s
                    var fechaFormateada =
            mes +
            '/' +
            date_format.getDate() +
            '/' +
            date_format.getFullYear() +
            ' ' +
            time
                    var lineasJ = []
                    lineaFibra.id_linea = uuid.v1()
                    lineasJ.push(lineaFibra)

                    const pedidoOrder = {
                        lineas: lineasJ
                    }

                    const order_infoJ = {
                        idDistribuidor: 0,
                        pedidoOrder: pedidoOrder,
                        estadoOrder: 'pendiente',
                        clienteCreado: 'false',
                        origen: 'web',
                        fecha: fechaFormateada,
                        id_pedido: uuid.v1(),
                        envioFirma: false,
                        fechaEnvioFirma: ''
                    }

                    this.$store.dispatch('setjsonPedido', order_infoJ)
                    this.go(this.$t('orderForm.errores.cobertura'), 'primary')
                } else {
                    order_infoJ.pedidoOrder.lineas[0].apartment_id = this.apartment_id

                    this.$store.dispatch('setjsonPedido', order_infoJ)
                    this.go(this.$t('orderForm.errores.cobertura'), 'primary')
                }
            } else {
                this.go(this.$t('orderForm.errores.coberturaDireccion'), 'error')
            }
        },
        reset () {
            this.calleSeleccionada = {}
        },
        crearJsonPedido () {
            var order_infoJ = this.$store.getters.getjsonPedido

            if (!order_infoJ) {
                var lineaFibra = this.$store.getters.getFibra
                var date_format = new Date()

                const mes = date_format.getMonth() + 1

                const h = this.addZero(date_format.getUTCHours())
                const m = this.addZero(date_format.getMinutes())
                const s = this.addZero(date_format.getSeconds())
                const time = h + ':' + m + ':' + s
                var fechaFormateada =
          mes +
          '/' +
          date_format.getDate() +
          '/' +
          date_format.getFullYear() +
          ' ' +
          time
                var lineasJ = []
                lineaFibra.id_linea = uuid.v1()
                lineasJ.push(lineaFibra)

                const pedidoOrder = {
                    lineas: lineasJ
                }

                const order_infoJ = {
                    idDistribuidor: 0,
                    pedidoOrder: pedidoOrder,
                    estadoOrder: 'pendiente',
                    clienteCreado: 'false',
                    origen: 'web',
                    fecha: fechaFormateada,
                    id_pedido: uuid.v1(),
                    envioFirma: false,
                    fechaEnvioFirma: ''
                }

                this.$store.dispatch('setjsonPedido', order_infoJ)
                this.go(this.$t('orderForm.errores.cobertura'), 'primary')
            } else {
                order_infoJ.pedidoOrder.lineas[0].apartment_id = this.apartment_id

                this.$store.dispatch('setjsonPedido', order_infoJ)
                this.go(this.$t('orderForm.errores.cobertura'), 'primary')
            }
        },

        comprobarDireccion () {
            if (this.selectNumeroCalle) {
                this.barraLoading = true

                const poblacion = this.calleSeleccionada.poblacion
                    .trim()
                    .replace(/\s+/g, '%20')
                const calle = this.calleSeleccionada.nombre
                    .trim()
                    .replace(/\s+/g, '%20')
                const goComprobarDireccion = async () => {
                    const config = {
                        timeout: this.espera,
                        headers: {
                            Accept: 'application/json'
                        }
                    }
                    try {
                        return await this.$axios.get(
                            this.url +
                'cobertura/poblacion/' +
                poblacion +
                '/calle/' +
                calle +
                '/numero/' +
                this.selectNumeroCalle.valor,
                            config
                        )
                    } catch (error) {
                        this.go(this.$t('errorConexionApi'), 'error')
                    }
                }

                const processComprobarDireccion = async () => {
                    const comprobarDireccion_result = await goComprobarDireccion()
                    if (comprobarDireccion_result) {
                        this.arrayDirecciones = comprobarDireccion_result.data.data
                        this.tipoVial = this.arrayDirecciones[0].tipo_vial

                        this.ocultarBotonCalleNumeroFormulario = true
                        this.barraLoading = false
                    } else {
                        this.barraLoading = false
                    }
                }

                processComprobarDireccion()
            }
        },
        buscarCodigoPostal () {
            this.selectNumeroCalle = ''
            this.calleSeleccionada = {}
            this.bloquearSelectorNumero = true
            this.calles = []
            this.arrayPuertas = []
            this.arrayNumerosCalles = []
            this.ocultarBotonCalleNumeroFormulario = false
            this.isDisabledList = true
            const goCodigoPostal = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + '/cobertura/cp/' + this.cp,
                        config
                    )
                } catch (error) {
                    this.go(this.$t('errorConexionApi'), 'error')
                }
            }

            const processCodigoPostalResult = async () => {
                const codigoPostal_result = await goCodigoPostal()
                if (codigoPostal_result.data.data.length > 0) {
                    codigoPostal_result.data.data.forEach((element) => {
                        var calle
                        let nombreConcatenado
                        if (element.tipo_vial === 'AV') {
                            nombreConcatenado = 'AVENIDA ' + element.vial
                        } else {
                            if (element.tipo_vial === 'CL') {
                                nombreConcatenado = 'CALLE ' + element.vial
                            } else {
                                if (element.tipo_vial === 'VT') {
                                    nombreConcatenado = 'VARIANTE ' + element.vial
                                } else {
                                    if (element.tipo_vial === 'PZ') {
                                        nombreConcatenado = 'PLAZA ' + element.vial
                                    } else {
                                        nombreConcatenado = element.vial
                                    }
                                }
                            }
                        }

                        calle = {
                            nombre: element.vial,
                            nombreConcatenado: nombreConcatenado,
                            poblacion: element.poblacion
                        }
                        this.calles.push(calle)
                    })
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.isDisabledList = false
                } else {
                    this.go(this.$t('orderForm.errores.coberturaCP'), 'error')
                    this.selectNumeroCalle = ''
                    this.calleSeleccionada = {}
                    this.bloquearSelectorNumero = true
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.calles.push()
                    this.arrayNumerosCalles = []
                    this.reset()
                    this.isDisabledList = true
                }
            }

            processCodigoPostalResult()
        },
        comprobarCP () {
            if (this.cp.length === 5) {
                this.buscarCodigoPostal()
                this.reset()

                this.calles = []
                this.arrayNumerosCalles = []
            }

            // else {
            //     this.go(this.$t('orderForm.errores.codigoPostal'), 'error')
            //     this.ocultarBotonCalleNumeroFormulario = true
            // }
        },

        enviarFormulario () {
            this.$store.dispatch('setdatosClienteValidados', false)

            var datePart = this.fecha_nacimiento.match(/\d+/g)
            var year = datePart[0] // get only two digits
            var month = datePart[1]
            var day = datePart[2]
            var fecha_nacimientoFormateada
            fecha_nacimientoFormateada = day + '/' + month + '/' + year
            var direccionFormateada

            const arrayTrocearNombreCalle = this.calleSeleccionada.nombre
                .toLocaleLowerCase()
                .split(' ')
            for (let i = 0; i < arrayTrocearNombreCalle.length; i++) {
                arrayTrocearNombreCalle[i] =
          arrayTrocearNombreCalle[i].charAt(0).toUpperCase() +
          arrayTrocearNombreCalle[i].slice(1)
            }
            const nombreCalleFormateada = arrayTrocearNombreCalle.join(' ')

            direccionFormateada =
        this.tipoVial +
        ' ' +
        nombreCalleFormateada +
        ', ' +
        this.selectNumeroCalle.valor +
        ' Planta ' +
        this.selectPlanta.piso +
        ' P' +
        this.selectPuerta.puerta
            const clienteJ = {
                subscriberType: this.subscriberType.id,
                documentType: this.documentType,
                documentoDni: 'sin dni del solicitante',
                empresa: this.empresa,
                fiscalId: this.fiscalId.toUpperCase(),
                contactFiscalId: this.contactFiscalId.toUpperCase(),
                nombre: this.nombre,
                apellidos: this.apellidos,
                calle: direccionFormateada,
                cp: this.cp,
                poblacion: this.poblacion,
                provincia: this.provincia,
                direccionFacturacionProvincia: this.direccionFacturacionProvincia,
                direccionFacturacionPoblacion: this.direccionFacturacionPoblacion,
                direccionFacturacionCp: this.direccionFacturacionCp,
                direccionFacturacionDireccion: this.direccionFacturacionDireccion,
                email: this.email,
                movil: this.movil,
                // numeroCuenta: this.numeroCuenta,
                fecha_nacimiento: fecha_nacimientoFormateada,
                nacionalidad: this.nacionalidad,
                documento_name: '',
                documento: ''
            }
            var order_infoJ = this.$store.getters.getjsonPedido
            order_infoJ.pedidoOrder.cliente = clienteJ

            this.$store.dispatch('setjsonPedido', order_infoJ)
            this.$store.dispatch('setdatosClienteValidados', true)
        }
    },
    created () {
        this.$store.commit('SET_CODIGOCOBERTURAFIBRA', '')
    }
}
</script>
